<template>
  <div class="pa-2 form-component ">
    <div class=" d-flex justify-space-between align-center">
      <!-- <pre>
        {{ $parent.attributes.images }}
      </pre> -->
      <label>Images ({{ images.length }}/255)</label>
      <div>
        <a class="link-add" @click="showModelAddImage = true"> Add image from URL</a>
        <!-- <a class="link-add ml-2">Add image</a> -->
      </div>
    </div>

    <div style="margin-top: 20px;">
      <UploadImages
        :columns="{ lg: 4, md: 4, sm: 6, xs: 12 }"
        :images="images"
        @callback="getImageUpLoad"
        @deleteCallback="getDeleteCallback"
      />

      <v-dialog persistent v-model="showModelAddImage" width="500">
        <v-card>
          <v-card-title>Add link image</v-card-title>
          <div class="close-icon" @click="showModelAddImage = false"><v-icon>fas fa-times</v-icon></div>
          <v-divider class="mb-3"></v-divider>
          <v-card-text class="">
            <v-text-field :error-messages="messageCheckImage" v-model="linkImage"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn @click="showModelAvailable = false">Cancel</v-btn> -->
            <v-btn color="primary" @click="submit">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import UploadImages from './UploadImages.vue';
export default {
  components: {
    UploadImages,
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showModelAddImage: false,
      messageCheckImage: '',
      linkImage: '',
    };
  },
  methods: {
    async checkImage(url) {
      var pattern = new RegExp(/^https?:\/\/.*\/.*\.(png|gif|webp|jpeg|jpg|webp)\??.*$/gim); // fragment locator
      return !!pattern.test(url);
    },
    objectId() {
      return this.hex(Date.now() / 1000) + ' '.repeat(16).replace(/./g, () => this.hex(Math.random() * 16));
    },

    hex(value) {
      return Math.floor(value).toString(16);
    },
    async submit() {
      this.messageCheckImage = '';
      let check = await this.checkImage(this.linkImage);
      if (check) {
        let obj = {
          alt: '',
          alt_text: '',
          src: this.linkImage,
          _id: this.objectId(),
        };
        this.linkImage = '';
        this.showModelAddImage = false;
      } else {
        this.messageCheckImage = 'Url Error';
      }
    },
    getImageUpLoad(obj) {
      this.images.push(obj.src);
      this.$emit('updateImages', this.images);
    },
    getDeleteCallback(key) {
      this.images.splice(key, 1);
      this.$emit('updateImages', this.images);
    },
  },
};
</script>
<style lang=""></style>
