<template>
  <div no-gutters>
    <v-row v-if="showImage">
      <draggable v-model="images" tag="v-row" class="">
        <v-col
          class="px-3 py-3"
          v-for="(img, key) in images"
          :key="`${key}k`"
          :lg="columns.lg"
          :md="columns.md"
          :sm="columns.sm"
          :xs="columns.xs"
        >
          <v-hover>
            <template class="px-3" v-slot:default="{ hover }">
              <v-card class="mx-auto" relative :max-width="maxWidth">
                <!-- Image -->
                <v-img
                  contain
                  :src="imageHelpers.url(img, { width: width, height: height })"
                  :lazy-src="imageHelpers.url(img, { width: width, height: height })"
                  max-height="150"
                  min-height="100"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <!-- Loading -->
                <v-row v-if="loading" class="loading-img fill-height ma-0 absolute" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey"></v-progress-circular>
                </v-row>
                <!-- overlay -->
                <v-fade-transition>
                  <v-overlay class="text-center btn-group-hover-image" v-if="hover" absolute color="#036358">
                    <!-- <v-btn min-width="auto" width="auto" @click="changeImage(key)">Change</v-btn> -->
                    <v-btn
                      class="ml-1"
                      min-width="auto"
                      width="auto"
                      @click="
                        showPreview = true;
                        preview.src = img;
                      "
                    >
                      <v-icon small>fas fa-eye</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="showAlt"
                      class="ml-1"
                      min-width="auto"
                      width="auto"
                      @click="
                        showEditAlt = true;
                        preview.src = img;
                      "
                    >
                      ALT
                    </v-btn>
                    <v-btn class="ml-1" min-width="auto" width="auto" @click="deleteFile(key)">
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </draggable>

      <v-col
        v-if="images && images.length > 0"
        class="px-3 py-3"
        :lg="columns.lg"
        :md="columns.md"
        :sm="columns.sm"
        :xs="columns.xs"
      >
        <div @click="clickUploadFile" :class="multiple ? 'upload-dragger' : 'upload-one-image'">
          <v-col class="px-3 py-3" cols="12">
            <div class="file-drop-area">
              <div class="text-center">
                <div class="fake-btn"><v-icon large>mdi-publish</v-icon></div>
                <div class="file-msg">upload files here</div>
              </div>
            </div>
          </v-col>
        </div>
      </v-col>
    </v-row>
    <v-btn v-if="!showImage" @click="clickUploadFile" class="mx-3"> Add Image </v-btn>
    <v-col v-if="showImage && images && images.length === 0" class="px-3 py-3" cols="12">
      <div @click="clickUploadFile" :class="multiple ? 'upload-dragger' : 'upload-one-image'">
        <v-col class="px-3 py-3" cols="12">
          <div class="file-drop-area">
            <div class="text-center">
              <div class="fake-btn"><v-icon large>mdi-publish</v-icon></div>
              <div class="file-msg">upload files here</div>
            </div>
          </div>
        </v-col>
      </div>
    </v-col>
    <input
      v-if="multiple"
      ref="fileUploader"
      @change="onChangeImage"
      class="myfile"
      accept="image/gif, image/jpeg, image/png , image/jpg"
      type="file"
      multiple
    />
    <input
      v-else
      ref="fileUploader"
      @change="onChangeImage"
      class="myfile"
      accept="image/gif, image/jpeg, image/png , image/jpg"
      type="file"
    />
    <v-dialog v-model="showPreview" width="500">
      <v-card>
        <v-card-title class="headline lighten-2"> Preview Image </v-card-title>
        <v-card-text>
          <v-img max-height="600" contain :src="imageHelpers.url(preview.src, { width: 1000, height: 1000 })"></v-img>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showPreview = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { uploadService } from '@/apis/upload.s';
import axios from 'axios';
import imageHelpers from '@/helpers/image';
import draggable from 'vuedraggable';

export default {
  props: {
    showImage: {
      type: Boolean,
      default: true,
    },
    hiddenDelete: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    images: {
      type: Array,
      default: function() {
        return [];
      },
    },
    maxWidth: {
      type: String,
      default: '250',
    },
    showAlt: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Object,
      default: function() {
        return { lg: 12, md: 12, sm: 12, xs: 12 };
      },
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
  },
  components: { draggable },
  data() {
    return {
      loadingImage: false,
      loading: false,
      imageHelpers,
      showPreview: false,
      preview: {
        alt: '',
        src: '',
      },
    };
  },
  methods: {
    clickUploadFile() {
      this.resetImageUploader();
      this.$refs.fileUploader.click();
    },
    resetImageUploader() {
      this.$refs.fileUploader.value = '';
    },
    deleteFile(key) {
      this.$emit('deleteCallback', key);
    },
    objectId() {
      return this.hex(Date.now() / 1000) + ' '.repeat(16).replace(/./g, () => this.hex(Math.random() * 16));
    },

    hex(value) {
      return Math.floor(value).toString(16);
    },
    async onChangeImage(e) {
      var ObjectID = require('bson').ObjectID;
      let uploadListImage = [];
      let inputTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];

      e.target.files.forEach(async file => {
        if (inputTypes.includes(file.type)) {
          try {
            let type = file.name.split('.')[file.name.split('.').length - 1];
            let id = this.objectId();
            let urlData = await uploadService.get({ filename: `lattehub-image-${id}.${type}` });
            if (urlData) {
              let res = await axios.put(urlData.data.presignedUrl, file, {
                headers: {
                  'Content-Type': `${file.type}`,
                },
              });
              let obj = {
                alt: file.name,
                alt_text: file.name,
                src: urlData.data.imagePath,
                _id: id,
                // fullPath: this.imageHelpers.url(urlData.data.imagePath),
              };

              // uploadListImage.push(obj);
              this.$emit('callback', obj);
            }
          } catch (error) {
            this.$store.commit('setMessages', {
              messages: 'Error image ',
              type: 'error',
            });
          }
        } else {
          alert('Type not support');
        }
      });
      //   console.log('uploadListImage', uploadListImage);
    },
  },
};
</script>

<style lang="scss">
.upload-one-image {
  width: 100%;
  min-height: 150px;
  max-height: 400px;
  cursor: pointer;
  border: 1px dashed #4181cb;

  padding: 2px;
  .text-upload {
    font-weight: bold;
    color: #4181cb;
    font-size: 13px;
  }

  i {
    font-size: 15px !important;
    color: #4181cb;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px dashed #4181cb;
  border-radius: 6px;
  box-sizing: border-box;
  //   width: 80px;
  //   height: 45px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.upload-dragger {
  padding: 5px;
  .text-upload {
    font-weight: bold;
    color: #4181cb;
  }

  i {
    font-size: 40px;
    color: #4181cb;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px dashed #4181cb;
  border-radius: 6px;
  box-sizing: border-box;
  width: 100%;
  min-height: 150px;
  max-height: 400px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.myfile {
  display: none;
}
</style>
