<template>
  <div>
    <v-data-table
      dense
      :headers="getReviewHeaders"
      :items="getReviews"
      item-key="_id"
      class="elevation-1 rv-table"
      :options="getReviewOptions"
      v-model="selected"
      show-select
      hide-default-footer
    >
      <template v-slot:[`item.rating`]="{ item }"> {{ item.rating }}/5 </template>
      <template v-slot:[`item.isPublished`]="{ item }">
        <v-chip class="ma-2" :color="getColor(item.isPublished)" label>
          {{ item.isPublished ? 'published' : 'unpublished' }}
        </v-chip>
      </template>
      <template v-slot:[`item.productType`]="{ item }" class="d-flex">
        <v-chip v-if="item.productType">{{ item.productType }}</v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }" class="d-flex">
        <div class="d-flex">
          <v-icon small class="mr-2" @click="openEditDialog(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteReview(item._id)">
            mdi-delete
          </v-icon>
        </div>
      </template>
      <template v-slot:top>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <h2>Edit review</h2>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.customerName" placeholder="Customer Name"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.review" placeholder="Review"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-rating color="yellow" length="5" small v-model="editedItem.rating"></v-rating>
                  </v-col>
                  <v-col cols="12">
                    <Images :images="images" @updateImages="updateImages" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="update(editedItem)">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="getReviewOptions.page"
        :length="getReviewOptions.totalPages || 1"
        @input="pageChange"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Images from './Images.vue';
export default {
  components: {
    Images,
  },
  props: {
    status: {
      type: String,
      default: 'published',
    },
    search: {
      type: String,
      default: '',
    },
    productId: {
      typeof: String,
      default: '',
    },
    type: {
      typeof: String,
      default: 'store',
    },
  },
  data: function() {
    return {
      dialog: false,
      editedItem: {},
      defaultItem: {},
      actions: [
        {
          title: 'Delete',
          hander: this.deleteReviews,
        },
        {
          title: 'Publish',
          hander: this.publish,
        },
        {
          title: 'Unpublish',
          hander: this.unpublish,
        },
      ],
      selected: [],
      images: [],
    };
  },
  async beforeMount() {
    const queries = this.$route.query;
    await this.$store.dispatch('fetchReviews', {
      status: this.getStatus,
      ...queries,
      type: this.type,
    });
  },
  async created() {},
  computed: {
    ...mapGetters(['getReviews', 'getReviewHeaders', 'getReviewOptions']),
    getStatus() {
      let computeStatus = this.status == 'tab-published' ? 1 : this.status == 'tab-unpublished' ? 0 : null;
      return computeStatus;
    },
  },
  methods: {
    getColor(isPublished) {
      let color = 'default';
      if (isPublished) {
        color = 'green';
      }
      return color;
    },
    async deleteMany() {
      if (confirm('Do you really want to delete?')) {
        let ids = this.selected.map(item => item._id).join(',');
        if (this.getReviews.length <= ids.length) {
          await this.$store.commit('setReviewOptions', { page: this.getReviewOptions.page - 1 });
          this.updateQuery({ page: this.getReviewOptions.page, search: this.search });
        }
        this.$store.dispatch('deleteReviews', {
          ids: ids,
          page: this.getReviewOptions.page,
          search: this.search,
        });
      }
    },
    async deleteReview(id) {
      if (confirm('Do you really want to delete?')) {
        if (this.getReviews.length <= 1) {
          await this.$store.commit('setReviewOptions', { page: this.getReviewOptions.page - 1 });
          this.updateQuery({ page: this.getReviewOptions.page, search: this.search });
        }

        if (this.type == 'product') {
          await this.$store.dispatch('deleteProductReviews', {
            ids: id,
            productId: this.productId,
            page: this.getReviewOptions.page,
            search: this.search,
            type: this.type,
          });
        } else {
          await this.$store.dispatch('deleteReviews', {
            ids: id,
            page: this.getReviewOptions.page,
            search: this.search,
            type: this.type,
          });
        }
      }
    },
    async update(review) {
      if (review.rating <= 3) {
        if (confirm('Rating is less than 3 point. Do you really want to update?')) {
          await this.$store.dispatch('updateReview', review);
        }
      } else {
        await this.$store.dispatch('updateReview', review);
      }
      await this.$store.dispatch('fetchReviews', {
        status: this.getStatus,
        page: this.getReviewOptions.page,
        search: this.search,
        type: this.type,
        product_id: this.productId,
      });
    },
    openEditDialog(item) {
      this.dialog = true;
      this.editedItem = Object.assign({}, item);
      this.images = item.images;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    pageChange(page) {
      let query = this.$route.query;
      let queries = {
        status: this.getStatus,
        search: query.search,
        page: page,
        limit: query.limit,
        type: this.type,
        productId: this.productId,
      };
      this.$store.dispatch('fetchReviews', queries);
      this.updateQuery(queries);
    },
    updateQuery(queries) {
      this.$router.push({
        query: queries,
      });
    },
    async updateImages(images) {
      this.editedItem.images = images;
      await this.update(this.editedItem);
    },
  },
  watch: {
    selected: function(val) {
      this.$emit(
        'inputChange',
        this.selected.map(item => item._id),
      );
    },
  },
};
</script>

<style scoped>
.rv-table {
  min-width: 1024px;
}
</style>
